import img_1 from '../../img/1.png';
import img_2 from '../../img/2.png';
import img_3 from '../../img/3.png';
import img_4 from '../../img/4.png';
import img_5 from '../../img/5.png';
import img_6 from '../../img/6.png';
import img_7 from '../../img/7.png';
import img_8 from '../../img/8.png';
import img_9 from '../../img/9.png';
import img_10 from '../../img/10.png';
import img_11 from '../../img/11.png';
import img_12 from '../../img/12.png';
import img_13 from '../../img/13.png';
import img_14 from '../../img/14.png';
import img_15 from '../../img/15.png';
import img_16 from '../../img/16.png';
import img_17 from '../../img/17.png';
import img_18 from '../../img/18.png';
import img_19 from '../../img/19.png';
import img_20 from '../../img/20.png';
import img_21 from '../../img/21.png';
import img_22 from '../../img/22.png';
import img_23 from '../../img/23.png';
import img_24 from '../../img/24.png';
import img_25 from '../../img/25.png';
import img_26 from '../../img/26.png';



export class OrigamiImages{

    1 = img_1

    2 = img_2

    3 = img_3

    4 = img_4

    5 = img_5

    6 = img_6

    7 = img_7

    8 = img_8

    9 = img_9

    10 = img_10

    11 = img_11

    12 = img_12

    13 = img_13

    14 = img_14

    15 = img_15

    16 = img_16

    17 = img_17

    18 = img_18

    19 = img_19

    20 = img_20

    21 = img_21

    22 = img_22

    23 = img_23

    24 = img_24
    25 = img_25
    26 = img_26


  }
  export default OrigamiImages;